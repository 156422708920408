.c-red{
    color:red !important;
}
.align-items-baseline{
    align-items: baseline;
}
.w-100{
    width: 100%;
}
.bg-footer {
    background-color: #0e2e50!important;
}
.ql-container {
    min-height: 400px !important;
    height: auto !important;
    max-height: 500px !important;
}
