/* Container and main carousel styling */
.collage-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 50px;
}

.collage-carousel-image {
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  transition: transform 0.5s, top 1s, left 1s;
}

.zoom {
  transform: scale(1.5); /* Zoom in effect */
}


.collage-carousel {
  width: 52%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  background: #fff;
}

@keyframes zoomInOut {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(5); } /* Zoomed-in effect */
}

.collage-carousel-image.zoom {
  animation: zoomInOut 3s ease-in-out; /* Apply zoom in and out animation */
}


@keyframes randomMove {
  0% { transform: translate(0, 0); }
  25% { transform: translate(10px, -10px); }
  50% { transform: translate(-10px, 10px); }
  75% { transform: translate(10px, 10px); }
  100% { transform: translate(-10px, -10px); }
}

@keyframes randomZoom {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.5); }
}


.collage-carousel-image.focus {
  width: 150px; /* Larger size for focused image */
  max-height: 150px;
  z-index: 10;
}

/* Animation: Slide horizontally */
@keyframes slide-horizontal {
  0% {
    transform: translateX(0); /* Starting position */
  }
  50% {
    transform: translateX(20px); /* Slide 20px to the right */
  }
  100% {
    transform: translateX(0); /* Return to original position */
  }
}

/* Rotate animation */
@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

/* Text box styles */
.collage-text-box {
  align-items: center;
  width: 48%;
  height: 100%;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  flex-direction: column;
  justify-content: center;
}

.collage-heading {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../images/bg3.jpg);
  transform: skew(30deg);
}

.collage-heading h2 {
  transform: skew(-30deg); /* Counter the skew of the text */
  margin: 0;
}

.image-text {
  width: 100%; /* Adjusted to balance with the image carousel */
  height: 50%;
  font-size: 1rem;
  font-weight: 100;
}

.collage-button {
  text-align: center;
  width: 100%;
  height: 30%;
}

.collage-button a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #00B98E;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.collage-button a:hover {
  background-color: #0056b3;
}

