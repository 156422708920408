
.chatbot {
    position: fixed;
    /* height: -webkit-fill-available; */
    bottom: 13%;
    right: 20px;
    width: 300px;
    height:260px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .chatbot-header {
    /* position: sticky; */
    /* padding: 10px; */
    height: 45px;
    background-color: #4CAF50;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
  }
  
  .chatbot-header h2 {
    margin: 0;
  }
  
  .chatbox {
    list-style-type: none;
    margin: 0;
    /* padding-top: 65px; */
    height: 60%;
    overflow-y: auto;
  }
  
  .chat {
    display: flex;
    align-items: flex-start;
    /* flex-direction: column; */
    padding: 10px;
  }
  
  .chat.user {
    justify-content: flex-end;
  }
  
  .chat p {
    margin: 0;
    /* padding: 8px 12px; */
    text-align: center;
    background-color: #F1F0F0;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
  }
  
  .chat.bot p {
    background-color: #4CAF50;
    color: #fff;
  }
  
  .chat-input {
    display: flex;
    align-items: center;
    /* padding: 10px; */
    height: 50px;
    border-top: 1px solid #ccc;
  }
  
  .chat-input textarea {
    flex: 1;
    min-height: 40px;
    /* max-height: 100px; */
    margin: 0;
    padding: 8px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .chat-input textarea:focus {
    outline: none;
    border-color: #4CAF50;
  }
  
  #send-btn {
    cursor: pointer;
    margin-left: 10px;
    padding: 8px;
    border-radius: 50%;
    background-color: #4CAF50;
    color: #fff;
  }
  .chatbot-btn-box {
    width: 5%;
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 999;
  }
  
  .chatbot-btn-2 {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 100%; /* Making the button round */
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: inline-block;
  width: 40px; /* Set width and height to match the image */
  height: 40px;
  background-image: url('../images/hero-bg-ol.jpg'); /* Replace 'path_to_your_image.png' with the path to your image */
  background-size: cover; /* Make sure the image covers the button */
  background-repeat: no-repeat; /* Prevent image from repeating */
  background-position: center; /* Center the image */
  border: none; /* Remove border */
 
  }
  
  .chatbot-btn-2:hover {
    background-color: #0056b3;
  }
  h2{
    color: #fff;
    text-align: center;
  }
  .left {
    display: grid;
    background-color: #e0e0e0; /* Bot message background color */
    margin-right: auto;
    justify-content: flex-start;
  }
  
  .right {
    display: grid;
    background-color: #e0e0e0; /* User message background color */
    color: white;
    margin-left: auto;
    justify-content: end;
  }
  .material-symbols-outlined{
    font-size: 10px;
  }
    