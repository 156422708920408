/* StripePayment.css */

.stripe-container-fluid {
    padding-top: 20%;
    display: flex;
  }
  
  .image-container {
    flex: 0 0 35%;
    /* padding-right: 20px; */
  }
  
  .image-container .card {
    width: 100%;
  }
  
  .image-container img {
    max-width: 100%;
  }
  
  .stripe-main-content {
    flex: 1;
  }
  /* StripePayment.css */

.meet-details {
    font-size: 1.5rem; /* Adjust the font size as needed */
    font-weight: bold; /* Make the text bold */
    margin-bottom: 10px; /* Add margin bottom for spacing */
  }
  /* .stripe-form-select{
    width:50%;
    padding:0.375rem 2.25rem 0.375rem 0.75rem; 
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    appearance: none;
  } */
  /* .stripe-form-input{
    text-align: center;
    width:100%;
  } */
  .stripe-card{
    width:100%;
    height:600px;
    padding: 20px;
    border-radius: 10px;
    background-color: #071a52;
    align-items: center;
    color:#fff;
    position: relative;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
  }
  .x-row {
    display: flex;
    flex-direction: row;
    width: 400px;
  }
  .stripe-form-row .col-md-3 {
    flex: 0 0 35%; /* Adjust the width as needed */
    padding-right: 20px; /* Add padding between the fields */
  }
  
  .stripe-form-row .col-md-9 {
    flex: 1; /* Fill remaining space */
  }
  .stripe-form-select {
    /* margin-top: 2rem; */
    height: calc(1.5em + 0.75rem + 2px);
    width: 100%; /* Make the select element fill its container */
  }
  
  .stripe-form-input {
    width: 100%; /* Make the input element fill its container */
  }
  .pl-15{
    padding-left: 15px;
  }
  
  