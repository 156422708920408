/********** Template CSS **********/
:root {
  --primary: #00B98E;
  --secondary: #FF6922;
  --light: #EFFDF5;
  --dark: #0E2E50;
}
#root {
  background-color: #fff !important;
}

.body {
  margin: 0;
  font-family: "Heebo",sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #666565;
  background-color: #fff !important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}
.m-5{
  margin-top: 5rem !important;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}


/*** Navbar ***/
.nav-bar {
  position: relative;
  margin-top: 45px;
  padding: 0 3rem;
  transition: .5s;
  z-index: 9999;
}

.nav-bar.sticky-top {
  position: sticky;
  padding: 0;
  z-index: 9999;
}

.navbar {
  box-shadow: 0 0 30px rgba(0, 0, 0, .08);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 5px;
  transition: .5s;
}

.navbar .dropdown-toggle[aria-expanded=true]::after {
  transform: rotate(-180deg);
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #FFFFFF;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary) !important;
  border-bottom: 4px solid #00B98E;
}

@media (max-width: 991.98px) {
  .nav-bar {
      margin: 0;
      padding: 0;
  }

  .navbar-light .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar-light .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

.navbar-light .navbar-brand {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      top: 100%;
      margin-top: 0;
      transform: rotateX(-75deg);
      transform-origin: 0% 0%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
      
  }

  .navbar .nav-item:hover .dropdown-menu {
      transform: rotateX(0deg);
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
@media (min-width: 992px) {
  .header {
      margin-top: -120px;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  margin: 7px 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: var(--primary);
  border-radius: 40px;
  font-size: 20px;
  transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--dark);
}

@media (max-width: 768px) {
  .header-carousel .owl-nav {
      left: 25px;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #DDDDDD;
}


/*** Icon ***/
.icon {
  padding: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF !important;
  border-radius: 50px;
  border: 1px dashed var(--primary) !important;
}


/*** About ***/
.about-img img {
  position: relative;
  z-index: 2;
}

.about-img::before {
  position: absolute;
  content: "";
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: var(--primary);
  transform: skew(20deg);
  z-index: 1;
}

.blog-height {
  height: 400px;
}
/*** Category ***/
.cat-item div {
  background: #FFFFFF;
  border: 1px dashed rgba(0, 185, 142, .3);
  transition: .5s;
}

.cat-item:hover div {
  background: var(--primary);
  border-color: transparent;
}

.cat-item div * {
  transition: .5s;
}

.cat-item:hover div * {
  color: #FFFFFF !important;
}


/*** Property List ***/
.nav-pills .nav-item .btn {
  color: var(--dark);
}

.nav-pills .nav-item .btn:hover,
.nav-pills .nav-item .btn.active {
  color: #FFFFFF;
}

.property-item {
  box-shadow: 0 0 30px rgba(0, 0, 0, .08);
}

.property-item img {
  transition: .5s;
}

.property-item:hover img {
  transform: scale(1.1);
}

.property-item .border-top {
  border-top: 1px dashed rgba(0, 185, 142, .3) !important;
}

.property-item .border-end {
  border-right: 1px dashed rgba(0, 185, 142, .3) !important;
}


/*** Team ***/
.team-item {
  box-shadow: 0 0 30px rgba(0, 0, 0, .08);
  transition: .5s;
}

.team-item .btn {
  color: var(--primary);
  background: #FFFFFF;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
}

.team-item .btn:hover {
  color: #FFFFFF;
  background: var(--primary);
}

.team-item:hover {
  border-color: var(--secondary) !important;
}

.team-item:hover .bg-primary {
  background: var(--secondary) !important;
}

.team-item:hover .bg-primary i {
  color: var(--secondary) !important;
}


/*** Testimonial ***/
.testimonial-carousel {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 576px) {
  .testimonial-carousel {
      padding-left: 4rem;
      padding-right: 4rem;
  }
}

.testimonial-carousel .testimonial-item .border {
  border: 1px dashed rgba(0, 185, 142, .3) !important;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 40px;
  top: calc(50% - 20px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF !important;
  background: var(--primary) !important;
  border-radius: 40px !important;
  font-size: 20px !important;
  transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--dark);
}


/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 35px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
  border-color: var(--light);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .form-control {
  border-color: rgba(255,255,255,0.5);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.category-container {
  max-width: 600px;
}
.ruby-text {
  display: ruby-text;
}
.contact-container {
  border: 1px dashed rgba(0, 185, 142, .3);
}
.navbar-img-container {
  width: 70px; 
  height: 30px;
}
.team-container {
  max-width: 600px;
}
.testimonial-container {
  max-width: 600px;
}
.testimonial-img-container {
  width: 45px; 
  height: 45px;
}
.footer-container {
  max-width: 400px;
}
.navbar-bg-transparent {
  background-color: transparent;
}
.slick-arrow {
  background: #00b98e;
  z-index: 1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.slick-next::before, .slick-prev::before {
  font-size: 70px;
  color: #00B98E;
}
.slick-slider {
  position: relative;
}

.slick-prev,
.slick-next {
  background-color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  right: 0 !important;
  left: -32px !important;
}

.slick-next {
  margin-top: 7%;
  left: -30px;
}

.slick-prev {
  transform: translateY(-50%); 
}

.slick-next {
  transform: translateY(-50%); 
}



.slick-prev {
  top: calc(50% - 30px); 
}

.slick-next {
  bottom: calc(50% - 30px); 
}
.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-dots {
  display: none !important;
}
.mtinpx-5 {
  margin-top: 5px;
}
.mtinperc-10{
  margin-top: 10%;
}

.carousel-image {
  width: 100%;
  height: 400px !important; /* You can adjust this height to fit your design */
  object-fit: cover; /* Ensures the image covers the set area without distortion */
}
